
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import "@fullcalendar/core/vdom";
import locale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";

import CSpinner from "@/components/spinner.vue";
import FFullCalendar, { CalendarOptions } from "@fullcalendar/vue3";

import { formatPhone, addMoneyMask, formatDateEn, formatDate } from "@/utils/formatters";

const VLaboratoryEstablishment = defineComponent({
  name: "VLaboratoryEstablishment",
  components: { CSpinner, FFullCalendar },
  setup() {
    useHead({ title: "Estabelecimentos | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const allEstablishment = computed(() => store.state.establishment.listByLaboratory);
    const fullCalendar = ref<{ $el: HTMLDivElement } | null>(null);
    const selectedDate = ref<string | null>(route.query.date?.toString() || null);
    const selectedDateEl = ref<HTMLElement | null>(null);

    const loading = reactive({ list: false });

    function handleEventDateClick(event: DateClickArg) {
      selectedDate.value = formatDate(event.dateStr);

      selectedDateEl.value?.classList.remove("selected-date");
      selectedDateEl.value = event.dayEl;
      selectedDateEl.value?.classList.add("selected-date");
    }

    function handleViewDidMount() {
      fullCalendar.value?.$el.querySelector(".fc-prev-button")?.addEventListener("click", handleDateSelection);
      fullCalendar.value?.$el.querySelector(".fc-next-button")?.addEventListener("click", handleDateSelection);
      fullCalendar.value?.$el.querySelector(".fc-today-button")?.addEventListener("click", handleDateSelection);
    }

    const calendarOptions: CalendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      initialDate: formatDateEn(route.query.date?.toString()) || formatDateEn(new Date().toDateString()),
      validRange: { start: new Date(), end: new Date().setDate(new Date().getDate() + 30) },
      dateClick: handleEventDateClick,
      viewDidMount: handleViewDidMount,
      locale,
    };

    async function getAllEstablishmentByLaboratory() {
      loading.list = true;
      await store.dispatch("getAllEstablishmentByLaboratory", {
        dt_agenda: formatDateEn(selectedDate.value),
        cd_procedimento: String(route.query.le).split(","),
      });
      loading.list = false;
    }

    watch(selectedDate, (date) => {
      getAllEstablishmentByLaboratory();
      router.replace({ query: { ...route.query, date } });
    });
    if (!route.query.date) selectedDate.value = formatDate(new Date().toISOString());

    if (route.query.le) getAllEstablishmentByLaboratory();
    else router.back();

    function handleDateSelection() {
      if (!selectedDate.value || !fullCalendar.value?.$el) return;

      setTimeout(() => {
        selectedDateEl.value =
          fullCalendar.value?.$el.querySelector(`[data-date="${formatDateEn(route.query.date)}"]`) || null;
        selectedDateEl.value?.classList.add("selected-date");
      }, 200);
    }

    handleDateSelection();
    onMounted(handleDateSelection);

    return {
      ...{ allEstablishment, fullCalendar, selectedDate, loading, calendarOptions },
      ...{ formatPhone, addMoneyMask },
    };
  },
});

export default VLaboratoryEstablishment;
